import React from "react";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { MdCall } from "react-icons/md";

const GetInTouch = () => {
  return (
    <div className="px-8 pb-[4.5rem] pt-[3rem] lg:py-20">
      <div className="wrapper text-left lg:text-left ">
        <div className="flex items-start justify-start gap-2 lg:flex lg:gap-6 lg:pb-10">
          <h1 className="font-sans text-2xl font-semibold text-custom-blue lg:text-[62px]">
            Get In Touch
          </h1>
          <h1 className="font-sans text-2xl font-normal text-black lg:text-[62px]">
            With Us
          </h1>
        </div>
        <div className="flex flex-col items-start justify-start gap-5 space-y-4 pt-6 text-lg lg:items-start lg:justify-start lg:gap-4 lg:pt-8">
          <div className="flex flex-col gap-4">
            <div className="flex lg:gap-8">
              <a
                href="tel:+919900003355"
                className="flex items-center lg:gap-8"
              >
                <MdCall className="mr-4 hidden text-2xl text-custom-blue lg:block" />
                <span className="text-sm text-black lg:text-base">
                  +91 9900003355
                </span>
              </a>
            </div>
            <div className="flex lg:gap-8">
              <a
                href="mailto:marketing@iconhomz.com"
                className="flex items-center lg:gap-8"
              >
                <FaEnvelope className="mr-4 hidden text-2xl text-custom-blue lg:block " />
                <span className="text-sm text-black lg:text-base">
                  marketing@iconhomz.com
                </span>
              </a>
            </div>
          </div>
          <div className="flex lg:flex lg:gap-8">
            <FaMapMarkerAlt className="mr-4 hidden text-xl text-custom-blue lg:block" />
            <div className="flex flex-col gap-5 lg:flex lg:flex-row lg:gap-20 ">
              <span className="text-left text-sm text-black lg:text-left lg:text-base">
                <b> Corporate Office</b> <br></br> Jai Plaza Symphony, 47/9, 9th
                Main,<br></br> Sector 6, HSR Layout, Bengaluru, <br></br>{" "}
                Karnataka - 560102
              </span>
              <span className="text-left text-sm text-black lg:text-left lg:text-base">
                <b>Site Office Address </b> <br></br> Grand Icon 3, No.61,
                Hosahalli Village,<br></br> Jigani Hobli, Taluk, Anekal,
                Bengaluru,<br></br>
                Karnataka 562106
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
