import { useState, useEffect, useRef } from "react";
import logo from "../assets/grand-icon-logo.svg";
import { MdCall } from "react-icons/md";
import SiteVisitForm from "./Components/SiteVisitForm";

const Navbar = () => {
  const [showForm, setShowForm] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const formRef = useRef(null);

  const toggleForm = (e) => {
    if (e) e.preventDefault(); // Prevent default only if the event is defined
    setShowForm((prevShowForm) => {
      const newShowForm = !prevShowForm;
      if (newShowForm && formRef.current) {
        formRef.current.scrollIntoView({ behavior: "smooth" });
      }
      return newShowForm;
    });
  };

  useEffect(() => {
    // Timer to automatically show the form after 5 seconds
    const timer = setTimeout(() => {
      toggleForm();
    }, 5000); // 5000 milliseconds = 5 seconds

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    const handleScroll = () => {
      const banner = document.getElementById("banner");
      if (banner) {
        const bannerHeight = banner.offsetHeight;
        setIsScrolled(window.scrollY > bannerHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showForm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showForm]);

  return (
    <div className="relative w-full">
      <div
        className={`wrapper fixed left-0 right-0 top-0 z-30 flex items-center justify-between px-4 py-3 lg:px-0 ${
          isScrolled ? "bg-white" : "bg-transparent"
        } transition-colors duration-300`}
      >
        <div className="flex-shrink-0 pb-5">
          <img src={logo} alt="Logo" className="h-7 lg:h-16" />
        </div>

        <div className="flex items-center gap-5">
          <button
            onClick={toggleForm}
            className="rounded bg-custom-blue px-[0.8rem] py-[0.4rem] font-[600] text-white hover:bg-custom-blue lg:px-[0.9rem] lg:py-[0.7rem] lg:text-lg"
          >
            Enquire Now
          </button>
          <a href="tel:9900003355">
            <button className="rounded bg-custom-blue px-[0.5rem] py-[0.4rem] text-white hover:bg-blue-900 lg:px-[0.9rem] lg:py-[0.8rem] lg:text-lg">
              <MdCall className="text-2xl" />
            </button>
          </a>
        </div>
      </div>

      {showForm && (
        <div className="backdrop-blur-xs fixed inset-0  z-40 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className="relative w-full max-w-md rounded-lg bg-white shadow-lg"
            ref={formRef}
          >
            <SiteVisitForm title="Enquire Now" className="px-4" />
            <button
              onClick={toggleForm}
              className="absolute right-2 top-2 text-gray-500 hover:text-gray-700"
            >
              <svg
                className="h-6 w-6 text-black"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
